import React from 'react';
import { navigate } from 'gatsby';
import { inject, observer } from 'mobx-react';
import { TypeUserStore } from '../../modules/Login/components/SignIn/store/user';

interface Props {
  location: string;
  user?: TypeUserStore;
  search?: string;
  locale?: string;
  component: React.ElementType;
}

@inject('user')
@observer
export class PrivateRoute extends React.Component<Props> {
  componentDidMount() {
    const { user, location } = this.props;
    const notOnLoginPage = location !== '/login';
    if (user && !user.profile.token && notOnLoginPage) {
      const {
        profile: { status },
        updateUserStatus,
      } = user;
      if (status === 'logged out') {
        updateUserStatus('unrecognized');
        return navigate('/', { replace: true });
      }
      return navigate('/login');
    }
  }

  render() {
    const { component: Component, user, ...rest } = this.props;
    if (user && !user.profile.token) {
      return null;
    }
    return <Component {...rest} location={this.props.location} />;
  }
}
